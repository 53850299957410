/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .altai-admin-icon,
.altai-theme-admin .icon {
  outline: none;
  border-radius: 50%;
  border: 2px solid transparent;
  height: 30px;
  width: 30px;
  background-color: transparent;
  color: #232323;
  margin-right: 5px;
  text-transform: uppercase;
  display: inline-block;
}
.altai-theme-admin .altai-admin-icon:hover,
.altai-theme-admin .icon:hover {
  border: 2px solid #0068ff;
  color: #0068ff;
}
.altai-theme-admin .altai-admin-icon.danger {
  border-color: red;
  color: red;
}
.altai-theme-admin .altai-admin-icon.main {
  border: 2px solid #0068ff;
  color: #0068ff;
}
.altai-theme-admin .altai-admin-icon.cancel {
  border: 2px solid #ECECEC;
  color: #ECECEC;
}
.altai-theme-admin button.iconmain {
  border: 2px solid #0068ff;
  outline: none;
  border-radius: 50%;
  border: 2px solid transparent;
  height: 30px;
  width: 30px;
  background-color: transparent;
  color: #232323;
  margin-right: 5px;
  text-transform: uppercase;
}
.altai-theme-admin button[disabled].altai-admin-icon.main,
.altai-theme-admin button[disabled].iconmain {
  border: #6C6C6C;
  color: #6C6C6C;
}
.altai-theme-admin button[enabled].iconmain:hover {
  outline: none;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: #0068ff;
  border: 2px solid #0068ff;
  color: white;
}
.altai-theme-admin button.icon.langicon {
  text-transform: none;
  border: none;
}
.altai-theme-admin button.icon.langicon:hover {
  text-transform: none;
  border: none;
}
.altai-theme-admin button.icon.langicon.active {
  background-color: #0068ff;
  border: 2px solid #0068ff;
  color: white;
}
